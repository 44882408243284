/*= ========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
// import { getAPI } from '@/axios-api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    role: false,
    token: '',
    isAuthenticated: false,
    isLoading: false,
    APIData: '',
    hours: 1, // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
    now: new Date().getTime(),
    setupTime: localStorage.getItem('setupTime'),
  },
  mutations: {
    updateStorage(state, { token }) {
      state.token = token
      state.isAuthenticated = true
      // state.refreshToken = refresh
    },
    destroyToken(state) {
      state.token = ''
      state.isAuthenticated = false
      // state.refreshToken = null
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
      localStorage.setItem('setupTime', state.now.toString())
    },
    setRole(state, role) {
      state.role = role
    },
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    removeToken(state) {
      state.role = false
      state.token = ''
      state.isAuthenticated = false
    },
  },
  getters: {
    loggedIn(state) {
      return state.token != null
    },
  },
  actions: {
    userLogout(context) {
      if (context.getters.loggedIn) {
        context.commit('destroyToken')
        localStorage.clear()
      }
    },
    userLogin(context, usercredentials) {
      axios.defaults.headers.common.Authorization = ''
      return new Promise((resolve, reject) => {
        axios.post('/auth/login/', {
          email: usercredentials.email,
          password: usercredentials.password,
        })
          .then(response => {
            const token = response.data.tokens.access
            const role = response.data.is_superuser
            context.commit('setToken', token)
            context.commit('setRole', role)
            axios.defaults.headers.common.Authorization = `Token ${token}`
            localStorage.setItem('token', token)
            localStorage.setItem('nom', response.data.nom)
            localStorage.setItem('prenom', response.data.prenom)
            localStorage.setItem('id', response.data.id)

            // context.commit('updateStorage', { token: response.data.token })
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  strict: process.env.NODE_ENV !== 'production',
  modules: {

  },
  plugins: [createPersistedState()],
})
